import React from "react";
import {docsService} from "../services/docs.service";
import {Detail} from "./Detail";
import {DocsSection} from "./DocsSection";

interface DocsModalProps {
    isModalOpen: boolean;
    onClose: () => void;
}

const Docs: React.FC<DocsModalProps> = ({isModalOpen = false, onClose}) => {
    const [search, setSearch] = React.useState('');
    const [searchResults, setSearchResults] = React.useState<any[]>([]);

    React.useEffect(() => {
        if (search) {
            setSearchResults(docsService.search(search));
        }
    }, [search]);

    return (
        <section className={`docs ${isModalOpen ? 'is-active' : ''}`}>
            <h2>Documentation</h2>
            <button className="modal__close" onClick={onClose}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor"
                     strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                    <line x1="18" y1="6" x2="6" y2="18"/>
                    <line x1="6" y1="6" x2="18" y2="18"/>
                </svg>
            </button>
            <input type="text" value={search} onChange={(e) => setSearch(e.target.value)} placeholder="Search"/>

            {search && searchResults.length > 0 &&
                <section className="docs__search">
                    <ul>
                        {searchResults.map((doc: any, index) => {
                            return (
                                <div key={index}>
                                    <Detail {...doc}/>
                                </div>
                            )
                        })}
                    </ul>
                </section>
            }

            {search && searchResults.length === 0 &&
                <p>No results found</p>
            }

            {search === '' &&
                <section>
                    <DocsSection title='Functions' docs={docsService.functionsByCategory}></DocsSection>
                    <DocsSection title='Filters' docs={docsService.filtersByCategory}></DocsSection>
                    <DocsSection title='Tests' docs={docsService.testsByCategory}></DocsSection>
                </section>
            }
        </section>
    );
}

export default Docs;