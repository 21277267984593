import React, {Dispatch, SetStateAction} from "react";

const Header: React.FC<{ showSettings: Dispatch<SetStateAction<boolean>>, showDocs: Dispatch<SetStateAction<boolean>> }> = ({showSettings, showDocs}) => {
    return (
        <header>
            <img src="textforge-text.svg" alt="TextForge"/>
            <nav>
                {/*<a href="#">Donate</a>*/}
                {/*<a href="#">Report a bug</a>*/}
                <button onClick={() => {
                    showSettings((prev) => !prev);
                }}>Settings
                </button>
                <button onClick={() => {
                    showDocs((prev) => !prev);
                }}>Docs
                </button>
            </nav>
        </header>
    );
}

export default Header;