import {ParseOptions} from '../../../core';
import {TransformRequest, TransformResponse} from '../../../workers/';

export class TransformationService {

    private readonly worker: Worker;

    private static _instance: TransformationService = new TransformationService();

    public static get instance(): TransformationService {
        return this._instance;
    }

    constructor() {

        if (typeof Worker === 'undefined') throw new Error('Web workers are not supported (global.Worker is undefined).');

        this.worker = new Worker('workers/Transformation.worker.js');

        this.worker.onerror = (e) => {
            console.error(`Worker Error: ${e.message} in ( ${e.filename}):${e.lineno}`);
        };

        this.worker.onmessageerror = (_) => {
            console.error(`Worker Message Error!`);
        };
    }

    public transformAsync(data: string, pattern: string, options?: Partial<ParseOptions>): Promise<TransformResponse> {

        return new Promise<TransformResponse>((resolve, reject) => {

            const timeout = setTimeout(() => reject('Transformation.worker timeout'), 5000);

            const channel: MessageChannel = new MessageChannel();

            channel.port1.onmessage = ({data: response}: MessageEvent<TransformResponse>) => {

                clearTimeout(timeout);
                if (!response.success) reject();
                resolve(response);
            };

            channel.port1.onmessageerror = (e: MessageEvent) => {
                clearTimeout(timeout);
                console.error(e);
                reject();
            };

            const msg: TransformRequest = {
                options: options,
                pattern: pattern,
                input: data,
            };

            this.worker.postMessage(msg, [channel.port2]);
        });

    }

    public destroy(): void {
        try {
            this.worker.terminate();
        } catch (e: any) {
            console.warn(`Error terminating worker.`)
            console.warn(e)
        }
    }


}
