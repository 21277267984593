import data from '../docs.json';

class DocsService {

    public lookupTable: {[key: string]:DocsReference} = {};
    public categories:  string[]  = [];
    public filtersByCategory: DocsType = {};
    public functionsByCategory: DocsType = {};
    public testsByCategory: DocsType = {};
    public allDocs: DocsType;

    constructor() {
        this.allDocs = data;
        this.populateCategoriesAndMethods('Filters', this.filtersByCategory);
        this.populateCategoriesAndMethods('Functions', this.functionsByCategory);
        this.populateCategoriesAndMethods('Tests', this.testsByCategory);
        this.categories = this.categories.sort((a: string, b: string) => {
            if (a === 'Other' && b !== 'Other') return 1;
            if (a !== 'Other' && b === 'Other') return -1;
            return a.localeCompare(b);
        });
    }

    public findMethod(methodName: string) {
        return this.lookupTable.hasOwnProperty(methodName) ? this.lookupTable[methodName] : null;
    }

    public search(searchTerm: string) {
        if (!searchTerm) {
            return [];
        }

        const results: DocsReference[] = [];
        Object.keys(this.allDocs).forEach((key) => {
            this.allDocs[key].forEach((doc) => {
                const searchString = searchTerm.trim().toLowerCase();
                if (doc.name.toLowerCase().includes(searchString)) {
                    results.push(doc);
                    return;
                }
                if (doc.description && doc.description.toLowerCase().includes(searchString)) {
                    results.push(doc);
                    return;
                }
            });
        });
        return results;
    }

    private populateCategoriesAndMethods(categoryName: string, categoryMap: DocsType) {
        // @ts-ignore
        data[categoryName].forEach((item) => {
            if (!this.categories.includes(item.category)) {
                this.categories.push(item.category);
            }

            if (!categoryMap[item.category]) {
                categoryMap[item.category] = [];
            }
            categoryMap[item.category].push(item);

            this.lookupTable[item.name] = item;
        });
    }
}


export type DocsType = { [key: string]: DocsReference[] };

export type DocsReference = {
    sig: string,
    name: string,
    description: string | null,
    params: {
        name: string,
        comment?: string | null,
        type: string,
        decl: string,
    }[],
    paramsText: string,
    type?: string,
    example: string | null,
    category: string
}

export const docsService = new DocsService();
