import React, {Dispatch, SetStateAction} from "react";
import {ParseOptions} from "../../../core";

import Modal from "./Modal";

interface SettingsModalProps {
    isModalOpen: boolean;
    onClose: () => void;
    parseOptions: ParseOptions;
    setparseOptions: Dispatch<SetStateAction<ParseOptions>>;
}

const SettingsModal: React.FC<SettingsModalProps> = ({isModalOpen = false, onClose, parseOptions, setparseOptions}) => {
    if (!isModalOpen) {
        return null;
    }

    return (
        <Modal isModalOpen={isModalOpen} onClose={onClose}>
            <h2>Settings</h2>
            <fieldset>
                <h3>CSV</h3>
                <label>
                    <input size={1} type="text" value={parseOptions.delimiter}
                           onChange={(e) => setparseOptions({...parseOptions, delimiter: e.target.value})}/>
                    Column separator
                </label>
                <label>
                    <input size={1} type="text" value={parseOptions.rowDelimiter}
                           onChange={(e) => setparseOptions({...parseOptions, rowDelimiter: e.target.value})}/>
                    Row separator
                </label>

                <label className="switch">
                    <input type="checkbox" checked={parseOptions.headers}
                           onChange={(e) => setparseOptions({...parseOptions, headers: e.target.checked})}/>
                    <span className="switch__slider">
                        <span className="switch__slider-handle"></span>
                    </span>
                    <span className="switch__text">Exclude first row</span>
                </label>
                <label className="switch">
                    <input type="checkbox" checked={parseOptions.skipEmptyLines}
                           onChange={(e) => setparseOptions({...parseOptions, skipEmptyLines: e.target.checked})}/>
                    <span className="switch__slider">
                        <span className="switch__slider-handle"></span>
                    </span>
                    <span className="switch__text">Remove empty lines</span>
                </label>
                <label className="switch">
                    <input type="checkbox" checked={parseOptions.trim}
                           onChange={(e) => setparseOptions({...parseOptions, trim: e.target.checked})}/>
                    <span className="switch__slider">
                        <span className="switch__slider-handle"></span>
                    </span>
                    <span className="switch__text">Trim double whitespace</span>
                </label>
            </fieldset>
        </Modal>
    );
}

export default SettingsModal;