import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import {App} from './App';
import reportWebVitals from './reportWebVitals';
import {loader, Monaco} from "@monaco-editor/react";
import {darculaTheme, themeName} from "./monaco/theme";
import {languageID} from "./monaco/config";
import {siltextLanguage} from "./monaco/siltext";
import {docsService} from "./services/docs.service";
import siltext from "./highlightjs/siltext";
import './highlightjs/siltext.darcula.css';
import hljs from 'highlight.js/lib/core';
import {SilTextCodeCompletionProvider} from "./monaco/code-completion";

hljs.registerLanguage('siltext', siltext);

loader.init().then((m: Monaco) => {
    m.editor.defineTheme(themeName, darculaTheme)
    m.languages.register({id: languageID});
    m.languages.setMonarchTokensProvider(languageID, siltextLanguage);
    m.languages.registerCompletionItemProvider(languageID, new SilTextCodeCompletionProvider());
    m.languages.registerHoverProvider(languageID, {
        provideHover: function (model, position) {
            const word = model.getWordAtPosition(position);
            if (!word) {
                return;
            }

            const helperText = docsService.findMethod(word.word);
            if (!helperText) {
                return;
            }

            return {
                range: new m.Range(position.lineNumber, position.column, position.lineNumber, position.column),
                contents: [
                    {value: `**${helperText.name}**`},
                    {value: `${helperText.description}\n\n**Syntax:** ${helperText.sig}\n\n**Example:**\n\n${helperText.example}`},
                ]
            };
        }
    });
});

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    <React.StrictMode>
        <App/>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
