import React from "react";
import { DocsReference } from "../services/docs.service";
import hljs from 'highlight.js/lib/core';

export const Detail = (doc: DocsReference) => {
    const example = doc.example ?  doc.example.replaceAll(/```(ts\n)?/gm,''): null;
    const codeHtml =  example ? hljs.highlight(example, {language: 'siltext'}).value : null;

    return (
        <details>
            <summary>{doc.name}</summary>
            <section>
                <p>{doc.description}</p>
                {doc.sig &&
                    <>
                        <h4>Signature</h4>
                        <p>{doc.sig}</p>
                    </>
                }
                {codeHtml &&
                    <>
                        <h4>Example</h4>
                        <pre><code dangerouslySetInnerHTML={{ __html: codeHtml}}></code></pre>
                    </>
                }
            </section>
        </details>
    );
}