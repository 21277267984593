import {DocsType} from "../services/docs.service";
import {Detail} from "./Detail";
import React from "react";

export const DocsSection:React.FC<{title: string, docs:DocsType}> = ({title, docs}) => {
    return(
        <section>
            <h2>{title}</h2>
            <ul className="docs__list">
                {Object.keys(docs).map((key) => {
                    return (
                        <li key={key}>
                            <h3>{key}</h3>
                            <ul>
                                {docs[key].map((doc: any, index: number) => {
                                    return (
                                        <li key={index}>
                                            <Detail {...doc}/>
                                        </li>
                                    )
                                })}
                            </ul>
                            <br/>
                        </li>
                    )
                })}
            </ul>
        </section>
    );
}