import React from "react";

interface modalProps {
    isModalOpen: boolean;
    onClose: () => void;
    children: React.ReactNode;

}

const closeModal = (e: React.MouseEvent<HTMLDivElement>, onClose: modalProps['onClose']) => {
    if (e.target === e.currentTarget) {
        e.preventDefault();
        e.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();
        onClose();
    }
}

const Modal:React.FC<modalProps> = ({ isModalOpen = false, onClose, children}) => {
    if (!isModalOpen) {
        return null;
    }

    return (
        <div className="modal-background" onClick={(e) => closeModal(e, onClose)}>
            <div className="modal">
                <button className="modal__close" onClick={onClose}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor"
                         strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                        <line x1="18" y1="6" x2="6" y2="18"/>
                        <line x1="6" y1="6" x2="18" y2="18"/>
                    </svg>
                </button>
                {children}
            </div>
        </div>
    );
}

export default Modal;