import {CancellationToken, editor, languages, Position} from "monaco-editor";
import {CodeCompletionRequest, CodeCompletionResponse} from "../../../workers";
import CompletionItemProvider = languages.CompletionItemProvider;

export class SilTextCodeCompletionProvider implements CompletionItemProvider {

    private _triggerCharacters?: string[] = ['|'];

    public readonly worker: Worker;

    public constructor() {

        if (typeof Worker === 'undefined') throw new Error('Web workers are not supported (global.Worker is undefined).');

        this.worker = new Worker('workers/CodeCompletion.worker.js');

        this.worker.onerror = (e) => {
            console.error(`Worker Error: ${e.message} in ( ${e.filename}):${e.lineno}`);
        };

        this.worker.onmessageerror = (_) => {
            console.error(`Worker Message Error!`);
        };
    }

    public get triggerCharacters(): string[] | undefined {
        return this._triggerCharacters;
    }

    public provideCompletionItems(model: editor.ITextModel, position: Position, context: languages.CompletionContext, token: CancellationToken): languages.ProviderResult<languages.CompletionList | undefined> {

        return new Promise<languages.CompletionList | undefined>((resolve, reject) => {

            const timeout = setTimeout(() => reject('Transformation.worker timeout'), 5000);

            const channel: MessageChannel = new MessageChannel();

            channel.port1.onmessage = ({data: response}: MessageEvent<CodeCompletionResponse>) => {

                clearTimeout(timeout);

                if (!response.success) reject();

                const completions: languages.CompletionList = {
                    incomplete: false,
                    suggestions: response.candidates!.map((candidate) => {

                        let insertText: string;

                        switch (candidate.type) {
                            case 1:
                                insertText = `${candidate.name}()`;
                                break;
                            case 2:
                                insertText = `|${candidate.name}()`;
                                break;
                            case 3:
                                insertText = `is ${candidate.name}`;
                                break;
                            default:
                                return null;
                        }

                        const item: languages.CompletionItem = {
                            label: candidate.name,
                            kind: languages.CompletionItemKind.Function,
                            insertText: insertText,
                            range: {
                                startLineNumber: position.lineNumber,
                                startColumn: position.column - 1,
                                endLineNumber: position.lineNumber,
                                endColumn: position.column
                            }
                        };
                        return item;

                    }).filter(item => item) as languages.CompletionItem[]

                };

                resolve(completions);

            };

            channel.port1.onmessageerror = (e: MessageEvent) => {
                clearTimeout(timeout);
                console.error(e);
                reject();
            };

            const msg: CodeCompletionRequest = {
                pattern: model.getValue(),
                lineNumber: position.lineNumber,
                column: position.column,
            };


            this.worker.postMessage(msg, [channel.port2]);
        });

    }
}