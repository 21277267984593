import React, {useEffect, useRef, useState} from 'react';
import './App.css';
import Header from "./components/Header";
import Docs from "./components/Docs";
import Editor from '@monaco-editor/react';
import {editor} from 'monaco-editor';
import {themeName} from "./monaco/theme";
import {languageID} from "./monaco/config";
import {createPatternOptions} from "./monaco/editor";
import SettingsModal from "./components/SettingsModal";
import {sanitizeDelimiter, sanitizeRowDelimiter} from "./services/helpers";
import {TransformationService} from "./services/transformation.service";
import {ParseOptions} from "../../core";


export const App: React.FC = () => {
    const inputEditorRef = useRef<editor.IStandaloneCodeEditor | null>(null);
    const patternEditorRef = useRef<editor.IStandaloneCodeEditor | null>(null);
    const outputEditorRef = useRef<editor.IStandaloneCodeEditor | null>(null);
    const [parseOptions, setParseOptions] = useState<ParseOptions>({
        headers: true,
        trim: true,
        delimiter: ',',
        rowDelimiter: '\\n',
        skipEmptyLines: true,
    });
    const [showSettingsModal, setshowSettingsModal] = useState(false);
    const [showDocsModal, setshowDocsModal] = useState(false);


    const clearOutput = (): void => {
        outputEditorRef.current?.setValue('');
    };

    const transformOnChange = async (input: string, pattern: string): Promise<void> => {
        const options = {
            headers: parseOptions.headers,
            delimiter: sanitizeDelimiter(parseOptions.delimiter),
            rowDelimiter: sanitizeRowDelimiter(parseOptions.rowDelimiter),
            skipEmptyLines: parseOptions.skipEmptyLines,
            trim: parseOptions.trim,
        }
        const response = await TransformationService.instance.transformAsync(input, pattern, options);

        if (response.success) {

            outputEditorRef.current?.setValue(response.output ?? '');
        } else {
        }
    }

    const onInputEditorMount = (editor: editor.IStandaloneCodeEditor): void => {
        inputEditorRef.current = editor;
    }

    const onPatternEditorMount = (editor: editor.IStandaloneCodeEditor): void => {
        patternEditorRef.current = editor;
    }

    const onOutputEditorMount = async (editor: editor.IStandaloneCodeEditor): Promise<void> => {
        outputEditorRef.current = editor;
        await transformOnChange(inputEditorRef.current?.getValue() ?? '', patternEditorRef.current?.getValue() ?? '');
    }

    const inputAndOutputOptions: editor.IStandaloneEditorConstructionOptions = {
        readOnly: false,
        fontSize: 14,
        scrollBeyondLastLine: false,
        minimap: {
            enabled: false
        },
        automaticLayout: true,
        contextmenu: false,
        lineNumbers: "on"
    };

    async function handleEditorChange(value: string | undefined): Promise<void> {
        if (!value || !patternEditorRef.current?.getValue()) {
            clearOutput();
            return;
        }
        await transformOnChange(value, patternEditorRef.current.getValue());
    }

    async function handlePatternChange(value: string | undefined): Promise<void> {
        if (!value || !inputEditorRef.current?.getValue()) {
            clearOutput();
            return;
        }
        await transformOnChange(inputEditorRef.current.getValue(), value);
    }

    useEffect(() => {
        if (inputEditorRef.current && patternEditorRef.current) {
            transformOnChange(inputEditorRef.current.getValue(), patternEditorRef.current.getValue()).then().catch(console.error);
        }
        // eslint-disable-next-line
    }, [parseOptions]);

    return (
        <div className="App">
            <Header showSettings={setshowSettingsModal} showDocs={setshowDocsModal}></Header>
            <div className="editor__bottom-bar">
                <h3>Input</h3>
                <div>
                    <label>
                        <input width="10" type="text" size={1} value={parseOptions.delimiter}
                               onChange={(e) => setParseOptions({...parseOptions, delimiter: e.target.value})}/>
                        Column separator
                    </label>
                    <label>
                        <input width="10" type="text" size={1} value={parseOptions.rowDelimiter}
                               onChange={(e) => setParseOptions({...parseOptions, rowDelimiter: e.target.value})}/>
                        Row separator
                    </label>
                </div>
            </div>
            <div className="editor">
                <Editor
                    onMount={onInputEditorMount}
                    theme={themeName}
                    defaultLanguage={'csv'}
                    defaultValue={`Firstname, Date of Employment, Vacation Days, Vacation Usage\nsamir, 01/01/2019, 15, 10\nlisa, 12/01/2012, 20, 7\njeff, 06/01/2006, 25, 5\ngwen, 08/16/2020, 15, 4`}
                    onChange={handleEditorChange}
                    options={inputAndOutputOptions}
                />
            </div>
            <div className="editor__bottom-bar">
                <h3>Transformer</h3>
            </div>
            <div className="editor">
                <Editor
                    onMount={onPatternEditorMount}
                    theme={themeName}
                    onChange={handlePatternChange}
                    defaultLanguage={languageID}
                    defaultValue={`Hello {{ $0|titleCase }}, you have {{ $2 - $3 }} days of vacation left!`}
                    options={createPatternOptions()}
                />
            </div>
            <div className="editor__bottom-bar">
                <h3>Output</h3>
            </div>
            <div className="editor">
                <Editor
                    onMount={onOutputEditorMount}
                    theme={themeName}
                    defaultLanguage={'text'}
                    defaultValue=""
                    options={inputAndOutputOptions}
                />
            </div>
            <Docs
                isModalOpen={showDocsModal}
                onClose={() => setshowDocsModal(prev => !prev)}
            ></Docs>
            <SettingsModal
                isModalOpen={showSettingsModal}
                parseOptions={parseOptions}
                setparseOptions={setParseOptions}
                onClose={() => {
                    setshowSettingsModal((prev) => !prev);
                }}></SettingsModal>
        </div>
    );
}