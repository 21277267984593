import {editor} from "monaco-editor";
import IStandaloneThemeData = editor.IStandaloneThemeData;

export const themeName = `darcula`;


export const darculaColors: editor.IColors = {
    // 'background': '#3c3f41',
    'editor.foreground': '#bababa',
    'editor.background': '#2b2b2b',
    'editorCursor.foreground': '#bbbbbb',
    'editor.lineHighlightBackground': '#323232',
    'editorLineNumber.foreground': '#606366',
    'editorLineNumber.activeForeground': '#a4a3a3',
    'editor.selectionBackground': '#1f4085',
    'scrollbar.shadow': '#1b1b1b',
}

export const darculaTheme: IStandaloneThemeData = {
    base: 'vs-dark',
    inherit: true,
    rules: [
        {token: '', foreground: '#a9b7c7', fontStyle: '',},
        {token: 'keyword', foreground: '#9975ab', fontStyle: 'bold',},
        {token: 'delimiter.tag', foreground: '#ce7827', fontStyle: '',},
        {token: 'variable', foreground: '#7bff79', fontStyle: '',},
        {token: 'variable.wild', foreground: '#7bff79', fontStyle: '',},
        {token: 'number', foreground: '#6695bc', fontStyle: '',},
        {token: 'string', foreground: '#698857', fontStyle: '',},
        {token: 'operators', foreground: '#c3cdd9', fontStyle: '',},
        {token: 'identifier', foreground: '#ffc765', fontStyle: '',},
    ],
    colors: darculaColors
};
