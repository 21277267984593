import * as monaco from "monaco-editor";


export const siltextLanguage: monaco.languages.IMonarchLanguage = {

    defaultToken: '',
    tokenPostfix: '',
    ignoreCase: true,

    keywords: [
        'for',
        'endfor',

        'if',
        'endif',

        'each',

        'is',

        // literals
        'true',
        'false',
        'undefined'
    ],

    tokenizer: {
        root: [
            // whitespace
            {regex: /\s+/},
            {include: '@variable'},
            [/{{/, 'delimiter.tag', '@exprTag'],
            [/{%/, 'delimiter.tag', '@blockTag'],
        ],

        variable: [
            [/\$h?-?[0-9a-zA-Z]+/, 'variable.wild.sil'],
        ],

        exprTag: [
            [/}}/, 'delimiter.tag', '@pop'],
            {include: '@expression'}
        ],

        blockTag: [
            [/%}/, 'delimiter.tag', '@pop'],

            {include: '@expression'}
        ],

        // /**
        //  * Block Tag Handling
        //  */
        // blockState: [
        //     [/%}/, 'delimiter.sil', '@pop'],
        //     // whitespace
        //     [/\s+/],
        //     {include: 'expression'}
        // ],
        //
        // /**
        //  * Variable Tag Handling
        //  */
        // variableState: [[/[-~]?}}/, 'delimiter.sil', '@pop'], {include: 'expression'}],
        //
        stringState: [
            // closing double quoted string
            [/"/, 'string.sil', '@pop'],
            // string part
            [/[^#"\\]*(?:(?:\\.|#(?!{))[^#"\\]*)*/, 'string.sil']
        ],
        // /**
        //  * Expression Handling
        //  */
        expression: [
            // whitespace
            {regex: /\s+/},
            // {include: '@variable'},

            [/\$h?-?[0-9a-zA-Z]+/, 'variable.sil'],

            // operators - math
            [/[+\-/%*]/, 'operators.sil'],
            [/\|/, 'operators.sil'],
            // // operators - logic
            [/(and|or|not|&&|\|\|)(\s+)/, ['operators.sil', '']],
            // operators - comparison (symbols)
            [/==|!=|<|>|>=|<=/, 'operators.sil'],
            // // operators - comparison (words)
            // [/(starts with|ends with|matches)(\s+)/, ['operators.sil', '']],
            // // operators - containment
            // [/(in)(\s+)/, ['operators.sil', '']],
            // // operators - test
            // [/(is)(\s+)/, ['operators.sil', '']],
            // operators - misc
            [/\||~|:|\.{1,2}|\?{1,2}/, 'operators.sil'],

            // // names
            // [
            //   /[^\W\d][\w]*/,
            //   {
            //     cases: {
            //       '@keywords': 'keyword.sil',
            //       // '@default': 'variable.sil'
            //     }
            //   }
            // ],
            // numbers
            [/\d+(\.\d+)?/, 'number.sil'],
            // punctuation
            [/[[\]{}]/, 'delimiter.sil'],
            [/[(),]/, 'operators.sil'],
            // strings
            [/"([^#"\\]*(?:\\.[^#"\\]*)*)"|'([^'\\]*(?:\\.[^'\\]*)*)'/, 'string.sil'],
            // opening double quoted string
            [/"/, 'string.sil', '@stringState'],

            // misc syntactic constructs
            // These are not operators per se, but for the purposes of lexical analysis we
            // can treat them as such.
            // arrow functions
            // [/=>/, 'operators.sil'],
            // assignment
            // [/=/, 'operators.sil']


            [/([a-z]|[A-Z])([a-zA-Z0-9_])*/, 'identifier.sil'],
        ]
    }
}
