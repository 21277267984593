export const sanitizeDelimiter = function(v: string | null): string {
    if (v === null || v.length === 0) return ',';
    return sanitizeWs(v);
}

export const sanitizeRowDelimiter = function(v: string | null): string {
    if (v === null || v.length === 0) return '\n';
    return sanitizeWs(v);
}

const sanitizeWs = function(v: string): string {
    return v.replaceAll('\\n', '\n')
        .replaceAll('\\r', '\r')
        .replaceAll('\\t', '\t');
}