import {defaultTheme} from "./config";


export function createInputOptions(theme: string = defaultTheme, fontSize: number = 14): any {
    return {
        theme: `csv-${theme}`,
        language: 'csv',
        readOnly: false,
        fontSize: fontSize,
        scrollBeyondLastLine: false, minimap: {
            enabled: false
        },
        automaticLayout: true,
        contextmenu: false,
        lineNumbers: "on",
    };
}

export function createPatternOptions(theme: string = defaultTheme, fontSize: number = 14): any {
    return {
        theme: theme,
        language: 'siltext',
        readOnly: false,
        fontSize: fontSize,
        scrollBeyondLastLine: false, minimap: {
            enabled: false
        },
        automaticLayout: true,
        contextmenu: false,
        lineNumbers: "on",
    };
}

export function createOutputOptions(theme: string = defaultTheme, fontSize: number = 14): any {
    return {
        theme: theme,
        language: 'text/plain',
        readOnly: true,
        fontSize: fontSize,
        scrollBeyondLastLine: false, minimap: {
            enabled: false
        },
        automaticLayout: true,
        contextmenu: false,
        lineNumbers: "on",
    };
}

